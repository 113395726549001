import { useEffect, useMemo } from "react";
import { produce } from "immer";
import * as cartAPI from "@ecp-redux/api/cart";
import * as searchResults from "@ecp-redux/api/searchResults";

const useBoxMiniCart = () => {
  const {
    data: miniCartData,
    isLoading: isMiniCartLoading,
    refetch: miniCartDataRefetch,
  } = cartAPI.useGetMiniCartByPortalUserTokenQuery();
  const {
    data: miniCartCountData,
    isLoading: isMiniCartCountLoading,
    refetch: miniCartCountRefetch,
  } = cartAPI.useGetMiniCartCountByPortalUserTokenQuery();

  const [getProducts, products] = searchResults.useLazyGetPostProductsQuery();

  const productsSkus = useMemo(
    () => miniCartData?.content?.orderLines.map((e) => e.productSku) || [],
    [miniCartData?.content?.orderLines.length]
  );

  useEffect(() => {
    const productsRequestBody = {
      skus: productsSkus,
      projection: ["coverPhoto", "sku"],
    };
    if (productsRequestBody.skus.length !== 0) {
      getProducts(productsRequestBody, true);
    }
  }, [productsSkus]);

  return {
    miniCartContent: {
      isMiniCartLoading,
      ...miniCartData,
      ...produce(miniCartData, (dataCopy) => {
        dataCopy?.content?.orderLines.forEach((line) => {
          line.image = products.data?.products?.find((e) => {
            return e.sku === line.productSku;
          })?.coverPhoto;
        });
      }),
    },
    miniCartCount: {
      count: miniCartCountData?.count ?? 0,
      isMiniCartCountLoading,
    },
    refetch: () => {
      miniCartDataRefetch();
      miniCartCountRefetch();
    },
  };
};

export default useBoxMiniCart;

export type IBoxMiniCart = ReturnType<typeof useBoxMiniCart>;
