import styled, { css } from "styled-components";
import { LoaderProps } from "./Loader.types";

export const StyledLoader = styled.div<LoaderProps>(
  ({
    size = 48,
    borderSize = 5,
    borderColor = { id: "custom", custom: "#FFF" },
    fullScreen,
  }) => css`
    .loader__spinner {
      width: ${size}px;
      height: ${size}px;
      border: ${borderSize}px solid ${borderColor};
      border-bottom-color: transparent;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
    }

    ${fullScreen &&
    css`
      position: fixed;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      z-index: 9999;
      background-color: black;
      opacity: 0.4;

      .loader__spinner {
        position: fixed;
        left: 50%;
        top: 50%;
      }
    `}

    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `
);
