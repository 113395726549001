import styled from "styled-components";
import { IBoxMiniCartSettings } from "../BoxMiniCart.types";

export const StyledProductWrapper = styled.div<IBoxMiniCartSettings>(
  ({ mini_cart_dropped_products_gap }) => {
    return `
        display: flex;
        justify-content: space-between;
        gap: 10px;
        margin:  ${mini_cart_dropped_products_gap} 0;
  
        .mini-cart-product-container__single-product-container {
        &__image {
          width: 110px;
        }
  
        &__product-data {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
  
        &__title {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 255px;
        }
  
        &__price-container {
          display: flex;
          margin-bottom: 0.25rem       
        }
  
        &__price-line-through {
          text-decoration: line-through;
          margin: 5px 0;
        }
  
        &__change-quantity-container {
          display: flex;
          justify-content: space-between;
        }
  `;
  }
);
