import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import withHydrationOnDemand from "react-hydration-on-demand";
import { checkBoolean } from "../../helpers/helpers";
import { isNotEmptyMessage } from "../../helpers/isNotEmptyMessage";
import Dropdown from "../../shared/components/Dropdown/Dropdown";
import StyledButtonWrapper from "../../shared/styleElements/StyledButton/StyledButtonWrapper.styled";
import StyledText from "../../shared/styleElements/StyledText/StyledText";
import { setPublicationZIndex } from "./BoxMiniCart.methods";
import {
  StyledBoxMiniCartWrapper,
  StyledDropdownWrapper,
} from "./BoxMiniCart.styled";
import { TBoxMiniCartProps } from "./BoxMiniCart.types";
import { MiniCartDetailed } from "./MiniCartDetailed";
import MiniCartProduct from "./MiniCartProduct/MiniCartProduct";
import MiniCartSimple from "./MiniCartSimple";
import MiniCartSummary from "./MiniCartSummary/MiniCartSummary";
import useBoxMiniCart from "./useBoxMiniCart";
import { Memo } from "@ecp-boxes/helpers/memoWrapper";

const BoxMiniCart: React.FC<TBoxMiniCartProps> = ({
  settings,
  messages,
  publicationRef,
}) => {
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  const { miniCartContent, miniCartCount, refetch } = useBoxMiniCart();
  const { t } = useTranslation();
  const router = useRouter();

  const { mini_cart_with_value } = settings;

  const handleMouseOver = () => {
    if (
      settings.mini_cart_action_on_icon === "HOVER" &&
      miniCartCount.count !== 0
    ) {
      setIsOpenDropdown(true);
      setPublicationZIndex("var(--zindex-dropdown-content)", publicationRef);
    }
  };

  const handleBackdropMouseOver = () => {
    if (settings.mini_cart_action_on_icon === "HOVER") {
      setPublicationZIndex("var(--zindex-publication)", publicationRef);
      setIsOpenDropdown(false);
    }
  };

  const handleMouseClick = () => {
    if (
      settings.mini_cart_action_on_icon === "REDIRECT" ||
      settings.mini_cart_action_on_icon === "HOVER"
    ) {
      router.push(settings.mini_cart_on_action_redirect_url);
      return;
    }

    if (
      settings.mini_cart_action_on_icon === "CLICK" &&
      miniCartCount.count > 0
    ) {
      setPublicationZIndex("var(--zindex-dropdown-content)", publicationRef);
      setIsOpenDropdown((prev) => !prev);
    }
  };

  const handleMouseLeave = () => {
    setIsOpenDropdown(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsOpenDropdown(false);
      setPublicationZIndex("var(--zindex-publication)", publicationRef);
    };

    document.addEventListener("scroll", handleScroll, false);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <StyledBoxMiniCartWrapper
      className="mini-cart-container"
      settings={settings}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseOver}
      onMouseEnter={refetch}
      data-testid="minicart"
    >
      <Dropdown
        isOpen={isOpenDropdown}
        setIsOpen={setIsOpenDropdown}
        openHorizontalDirection={settings.mini_cart_dropped_container_position}
        actionType={settings.mini_cart_action_on_icon}
        onBackDropClick={() => {
          setIsOpenDropdown(false);
        }}
        closeDropdown={handleMouseLeave}
        onBackDropMouseOver={handleBackdropMouseOver}
        fixedWidth
        buttonContent={
          checkBoolean(mini_cart_with_value) ? (
            <MiniCartDetailed
              key={JSON.stringify(settings)}
              handleMouseClick={handleMouseClick}
              messages={messages}
              miniCartCount={miniCartCount}
              settings={settings}
              miniCartContent={miniCartContent}
            />
          ) : (
            <MiniCartSimple
              handleMouseClick={handleMouseClick}
              messages={messages}
              miniCartCount={miniCartCount}
              settings={settings}
            />
          )
        }
        selectContainerContent={
          <StyledDropdownWrapper
            className="mini-cart-container__dropdown"
            margin={settings.mini_cart_dropped_wrapper_margin}
            gap={settings.mini_cart_dropped_elements_gap}
            data-testid="mini-cart-dropdown-wrapper"
            onMouseLeave={() => setIsOpenDropdown(false)}
          >
            <div className="mini-cart-container__dropdown__container">
              <StyledText
                className="mini-cart-container__dropdown__container__title"
                $settings={{
                  font: settings.mini_cart_dropped_title_typo,
                  text: {
                    color: settings.mini_cart_dropped_title_color,
                  },
                }}
                show={isNotEmptyMessage(messages.mini_cart_title)}
              >
                {messages.mini_cart_title}
              </StyledText>
              <StyledText
                className="mini-cart-container__dropdown__container__products-count"
                $settings={{
                  font: settings.mini_cart_dropped_summary_products_count_typo,
                  text: {
                    color:
                      settings.mini_cart_dropped_summary_products_count_color,
                  },
                }}
                show={isNotEmptyMessage(
                  messages.mini_cart_dropped_count_products_suffix
                )}
              >
                {`${miniCartCount.count ?? ""} ${
                  messages.mini_cart_dropped_count_products_suffix
                }`}
              </StyledText>
            </div>
            {miniCartContent.isMiniCartLoading || !miniCartContent ? (
              <div className="mini-cart-container__dropdown__container__loading">
                {t("global_keys.loading")}
              </div>
            ) : (
              <>
                <MiniCartProduct
                  content={miniCartContent.content?.orderLines}
                />

                <MiniCartSummary
                  content={miniCartContent?.content?.cartSummary}
                />
              </>
            )}
            <div className="mini-cart-container__dropdown__container__button-container">
              <StyledButtonWrapper
                className="mini-cart-container__dropdown__container__button-container__button-go-to-cart"
                onClick={() =>
                  router.push(settings.mini_cart_on_action_redirect_url)
                }
                style={{
                  width: settings.mini_cart_dropped_button_go_to_cart_width
                    ? settings.mini_cart_dropped_button_go_to_cart_width
                    : "fit-content",
                }}
                renderAs="button"
                type="submit"
                $settings={settings.mini_cart_dropped_button_go_to_cart_style}
                show={isNotEmptyMessage(
                  messages.mini_cart_dropped_button_go_to_cart_label
                )}
              >
                {messages.mini_cart_dropped_button_go_to_cart_label}
              </StyledButtonWrapper>
            </div>
          </StyledDropdownWrapper>
        }
      />
    </StyledBoxMiniCartWrapper>
  );
};

export default Memo(withHydrationOnDemand({ on: ["visible"] })(BoxMiniCart));
