import { isNotEmptyMessage } from "../../helpers/isNotEmptyMessage";
import StyledButton from "../../shared/styleElements/StyledButton/StyledButton";
import StyledText from "../../shared/styleElements/StyledText/StyledText";
import {
  IBoxMiniCartMessages,
  IBoxMiniCartSettings,
} from "./BoxMiniCart.types";
import { IBoxMiniCart } from "./useBoxMiniCart";

interface IMiniCartSimpleProps {
  handleMouseClick: () => void;
  settings: IBoxMiniCartSettings;
  messages: IBoxMiniCartMessages;
  miniCartCount: IBoxMiniCart["miniCartCount"];
}

const MiniCartSimple = ({
  handleMouseClick,
  settings,
  messages,
  miniCartCount: { count },
}: IMiniCartSimpleProps) => {
  return (
    <div
      className="mini-cart-container__button-open-container"
      data-testid="button-open-minicart"
      onClick={handleMouseClick}
    >
      <div className="mini-cart-container__button-open-container__text-content">
        <StyledButton
          $settings={settings.mini_cart_icon_button}
          renderAs={"button"}
          data-testid="dropdown-button"
          className="mini-cart-container__button-open-container__text-content"
          show={isNotEmptyMessage(messages.mini_cart_icon_open_label)}
        >
          {messages.mini_cart_icon_open_label}
        </StyledButton>
        <StyledText
          className="mini-cart-container__button-open-container__text-content__products-count"
          $settings={{
            font: settings.mini_cart_cart_products_count_typo,
            text: {
              color: settings.mini_cart_cart_products_count_color,
            },
          }}
        >
          {count > 0 && `(${count ?? ""})`}
        </StyledText>
      </div>
    </div>
  );
};

export default MiniCartSimple;
