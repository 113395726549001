import { useTheme } from "styled-components";
import { ICartSummary } from "@ecp-redux/dto/cart.types";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { formatPrice } from "../../../helpers/helpers";
import { isNotEmptyMessage } from "../../../helpers/isNotEmptyMessage";
import StyledText from "../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../structure/Contexts/MessagesSettingsContext";
import {
  IBoxMiniCartMessages,
  IBoxMiniCartSettings,
} from "../BoxMiniCart.types";
import {
  StyledMiniCartSummaryElement,
  StyledMiniCartSummaryGroup,
  StyledMiniCartSummaryWrapper,
  StyledSummaryTotalPriceElement,
} from "./MiniCartSummary.styled";

interface IMiniCartSummaryProps {
  content?: ICartSummary;
}

const MiniCartSummary: React.FC<IMiniCartSummaryProps> = ({ content }) => {
  const {
    advanceSettings: { messages: globalMessages },
  } = useTheme() as IThemeState;

  const { messages, settings } = useMessagesSettingsContext<
    IBoxMiniCartMessages,
    IBoxMiniCartSettings
  >();

  if (!content) return null;

  return (
    <StyledMiniCartSummaryWrapper gap={settings.mini_cart_dropped_elements_gap}>
      <StyledMiniCartSummaryGroup
        gap={settings.mini_cart_dropped_summary_elements_group}
      >
        <StyledMiniCartSummaryElement>
          <StyledText
            $settings={{
              font: settings.mini_cart_dropped_products_price_label_typo,
              text: {
                color: settings.mini_cart_dropped_products_price_label_color,
              },
            }}
            show={isNotEmptyMessage(
              messages.mini_cart_dropped_products_price_label
            )}
          >
            {`${messages.mini_cart_dropped_products_price_label}`}
          </StyledText>
          <StyledText
            $settings={{
              font: settings.mini_cart_dropped_products_price_value_typo,
              text: {
                color: settings.mini_cart_dropped_products_price_value_color,
              },
            }}
            show={isNotEmptyMessage(
              messages.mini_cart_dropped_products_price_label
            )}
          >
            {formatPrice(
              content.cartValueFromProducts,
              globalMessages["currencyShort"]
            )}
          </StyledText>
        </StyledMiniCartSummaryElement>
        {content.cartValueFromProducts - content.cartValueAfterDiscounts !==
          0 && (
          <StyledMiniCartSummaryElement>
            <StyledText
              $settings={{
                font: settings.mini_cart_dropped_savings_price_label_typo,
                text: {
                  color: settings.mini_cart_dropped_savings_price_label_color,
                },
              }}
              show={isNotEmptyMessage(
                messages.mini_cart_dropped_savings_price_label
              )}
            >
              {`${messages.mini_cart_dropped_savings_price_label}`}
            </StyledText>
            <StyledText
              $settings={{
                font: settings.mini_cart_dropped_savings_price_value_typo,
                text: {
                  color: settings.mini_cart_dropped_savings_price_value_color,
                },
              }}
              show={isNotEmptyMessage(
                messages.mini_cart_dropped_savings_price_label
              )}
            >
              {formatPrice(
                content.cartValueFromProducts - content.cartValueAfterDiscounts,
                globalMessages["currencyShort"]
              )}
            </StyledText>
          </StyledMiniCartSummaryElement>
        )}
        <StyledMiniCartSummaryElement>
          <StyledText
            $settings={{
              font: settings.mini_cart_dropped_delivery_price_label_typo,
              text: {
                color: settings.mini_cart_dropped_delivery_price_label_color,
              },
            }}
            show={isNotEmptyMessage(
              messages.mini_cart_dropped_delivery_price_label
            )}
          >
            {`${messages.mini_cart_dropped_delivery_price_label}`}
          </StyledText>
          <StyledText
            $settings={{
              font: settings.mini_cart_dropped_delivery_price_value_typo,
              text: {
                color: settings.mini_cart_dropped_delivery_price_value_color,
              },
            }}
            show={isNotEmptyMessage(
              messages.mini_cart_dropped_delivery_price_label
            )}
          >
            {formatPrice(
              content.cartDeliveryCosts,
              globalMessages["currencyShort"]
            )}
          </StyledText>
        </StyledMiniCartSummaryElement>
      </StyledMiniCartSummaryGroup>
      <StyledSummaryTotalPriceElement
        padding={settings.mini_cart_dropped_summary_elements_group}
      >
        <StyledText
          $settings={{
            font: settings.mini_cart_dropped_products_total_price_label_typo,
            text: {
              color:
                settings.mini_cart_dropped_products_total_price_label_color,
            },
          }}
          show={isNotEmptyMessage(
            messages.mini_cart_dropped_products_total_price_label
          )}
        >
          {`${messages.mini_cart_dropped_products_total_price_label}`}
        </StyledText>
        <StyledText
          $settings={{
            font: settings.mini_cart_dropped_products_total_price_value_typo,
            text: {
              color:
                settings.mini_cart_dropped_products_total_price_value_color,
            },
          }}
          show={isNotEmptyMessage(
            messages.mini_cart_dropped_products_total_price_label
          )}
        >
          {formatPrice(content.amountToPay, globalMessages["currencyShort"])}
        </StyledText>
      </StyledSummaryTotalPriceElement>
    </StyledMiniCartSummaryWrapper>
  );
};

export default MiniCartSummary;
